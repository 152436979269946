<!-- prettier-ignore -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 647.63 125.8" :height="height" :fill="color">
    <polygon points="614.19 60.14 644.39 22.85 629.39 22.85 595.39 64.58 595.39 22.85 582.92 22.85 582.92 103 595.39 103 595.39 81.6 606 68.92 632.24 103 647.63 103 614.19 60.14"/>
    <path d="M569.81,47.24A24.42,24.42,0,0,0,563.09,30q-6.68-7.16-19.18-7.15h-28.3V103h12.47V71.52H538.7L558.21,103h15.07L551.93,70.33C563.53,67.18,569.81,57.75,569.81,47.24ZM528.08,59.92V34.66H544c8.23,0,13,5.42,13,12.47,0,7.26-4.88,12.79-15,12.79Z"/>
    <path d="M467.36,22.9,435.49,103h13.44l7.59-19.78H486L493.59,103H507L475.49,22.9Zm-6.83,48.83,10.84-29.05,10.84,29.05Z"/>
    <path d="M434.65,30.33q-6.66-7.48-19.83-7.48H387.18V103h12.46V73.25h14.13c9.11,0,16.05-2.38,20.71-7.26a24.7,24.7,0,0,0,7-17.78A25.21,25.21,0,0,0,434.65,30.33ZM414.06,61.76H399.64V34.56h15.72c8.56,0,13.44,6,13.44,13.44S423.71,61.76,414.06,61.76Z"/>
    <path d="M364.68,61.76c-1.95-1.08-5.2-2.71-9.86-4.77s-7.59-3.68-9-4.44c-4.44-2.49-6.72-5.75-6.72-9.54,0-6,5-9.75,11.92-9.75,6.62,0,12.14,2.7,16.59,8.23l7.69-8.56c-6.18-7.7-14.3-11.49-24.39-11.49-7.47,0-13.54,2.17-18.21,6.39a21.3,21.3,0,0,0-4.63,6q9.07,12.27,9.17,28.79l.67.42c2.06,1.19,5.2,2.71,9.21,4.56s6.83,2.92,8.13,3.57c5.31,2.82,7.91,6.29,7.91,10.3,0,6.07-5.42,11-13.65,11a21.85,21.85,0,0,1-16.91-8A46.75,46.75,0,0,1,326,94.65a32.57,32.57,0,0,0,23.56,9.6c8,0,14.63-2.17,19.72-6.5A21.48,21.48,0,0,0,377,80.84C376.93,72.6,372.81,66.21,364.68,61.76Z"/>
    <path d="M317.24,33.47q-12-12-30-12c-12,0-22,4.09-30,12s-12,17.78-12,29.38a40.15,40.15,0,0,0,12,29.48c8,7.91,18.06,11.92,30.09,11.92s21.91-4,29.91-11.92,12-17.66,12-29.37S325.24,41.47,317.24,33.47Zm-16.64,43h0a19,19,0,0,1-26.56,0,18.54,18.54,0,0,1-5.42-13.65A18.56,18.56,0,0,1,274,49.19a18.07,18.07,0,0,1,13.27-5.42,17.86,17.86,0,0,1,13.11,5.31,19.15,19.15,0,0,1,5.53,13.77A18.34,18.34,0,0,1,300.6,76.5Z"/>
    <path d="M238.77,50.06c0-8-2.59-14.52-7.69-19.62s-12.66-7.59-22.66-7.59h-34.9v80.06h22.54v-25H203l25.53,47.81,25.69,0L225,74.06C234.44,69.24,238.77,60.14,238.77,50.06ZM207.66,58.4h-11.6V43.34H208.2c4.67,0,7.37,3.26,7.37,7.48S213,58.4,207.66,58.4Z"/>
    <polygon points="103.68 103 163.44 103 163.44 82.14 126.44 82.14 126.44 73.25 160.8 73.25 160.8 52.55 126.44 52.55 126.44 43.66 163.62 43.66 163.62 22.85 103.68 22.85 103.68 103"/>
    <polygon points="22.85 125.8 45.4 125.8 45.4 76.45 22.85 77.84 22.85 125.8"/>
    <polygon points="70.54 0 70.54 52.63 45.4 52.63 45.4 22.93 22.85 22.93 22.85 52.6 0 52.79 22.85 74.36 79.34 74.36 70.54 74.91 70.54 103.03 93.31 103.03 93.31 0 70.54 0"/>
  </svg>
</template>

<script>
export default {
  name: 'hsLogo',
  props: {
    color: {
      type: String,
      default: '#262626',
    },
    height: String,
  },
};
</script>
