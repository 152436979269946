<template>
  <div id="radial-progress" class="d-flex">
    <div id="back-left"></div>
    <div id="back-right"></div>
    <div id="front-left" :style="{ transform: `rotate(${frontLeftRotation}deg)` }"></div>
    <div
      id="front-right"
      :style="{ visibility: frontRightVisibility, transform: `rotate(${frontRightRotation}deg)` }"
    ></div>
    <div id="progress">{{ progress }}%</div>
  </div>
</template>

<script>
export default {
  name: 'HsRadialProgress',
  props: {
    progress: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      frontRightRotation: 0,
      frontLeftRotation: 0,
    };
  },
  watch: {
    progress() {
      this.frontRightRotation = this.progress * 3.6;
      if (this.progress > 50) this.frontLeftRotation = this.progress * 3.6 - 180;
    },
  },
  computed: {
    frontRightVisibility() {
      return this.frontRightRotation < 180 ? 'visible' : 'hidden';
    },
  },
};
</script>
<style lang="scss" scoped>
#radial-progress {
  position: relative;
  height: 120px;
  width: 120px;
}
#back-left {
  position: absolute;
  height: 120px;
  width: 60px;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
  border: 2px solid $cyan;
  border-right: 0;
  z-index: 1;
}
#back-right {
  position: absolute;
  margin-left: 50%;
  height: 120px;
  width: 60px;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  border: 2px solid $cyan;
  border-left: 0;
  z-index: 3;
}
#front-left {
  position: absolute;
  height: 120px;
  width: 60px;
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
  border: 2px solid gray;
  border-right: 0;
  z-index: 2;
  transform-origin: 100% 50%;
}
#front-right {
  position: absolute;
  margin-left: 50%;
  height: 120px;
  width: 60px;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  border: 2px solid gray;
  border-left: 0;
  z-index: 4;
  transform-origin: 0 50%;
}
#progress {
  margin-top: 40px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
</style>
