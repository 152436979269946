<template>
  <div>
    <b-button
      block
      v-b-toggle="id"
      class="btn-collapse d-flex justify-content-between align-items-center shadow-none rounded-pill"
    >
      <span class="font-weight-normal">{{ title }}</span>
      <hs-icon variant="light" :icon="visible ? 'chevron-down' : 'chevron-right'" />
    </b-button>
    <b-collapse :id="id" v-model="visible">
      <slot></slot>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'hsCollapse',
  data() {
    return {
      visible: !this.collapsed,
    };
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return this._uid.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-collapse {
  background-color: $grey-17;
  color: $grey-30;
  font-size: 16px;
  line-height: 20px;
  &:active {
    background-color: $grey-17 !important;
    color: $grey-30 !important;
  }
}

@media only screen and (min-width: $screen-bs-sm) {
  .btn-collapse {
    font-size: 14px;
  }
}
</style>
