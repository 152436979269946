<template>
  <b-modal
    centered
    :scrollable="scrollable"
    hide-footer
    :hideHeader="hideHeader"
    :title="title"
    :id="id"
    :modal-class="modalClass"
    :size="size"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    :body-class="bodyClass"
    :content-class="contentClass"
    @hidden="$emit('hidden')"
    @show="$emit('show')"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    id: {
      type: String,
      required: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    bodyClass: {
      type: String,
      default: '',
    },
    modalClass: {
      type: String,
      default: '',
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
};
</script>
