<template>
  <div>
    <div
      id="uploader"
      class="d-none d-md-flex flex-column justify-content-center align-items-center m-4 rounded border-2 border-dashed border-secondary"
      @dragover.prevent
      @drop.prevent="onFile"
      :style="{ height }"
    >
      <h5 class="mt-6">{{ theLabel1 }}</h5>

      <h6 class="mt-4">{{ theLabel2 }}</h6>

      <label id="file-select-btn" class="mt-4 mb-6">
        <div class="rounded px-4 py-2 text-white font-weight-bold tex-tcenter">
          {{ theButtonLabel }}
        </div>
        <input id="img-input" type="file" :accept="acceptedFormats" class="d-none" @input="onFile" />
      </label>
      <slot name="footer" />
    </div>
    <slot name="extra" />
    <div class="d-flex flex-column flex-md-row justify-content-end mr-4">
      <MXButton variant="secondary" @click="$emit('cancel')">
        {{ $t('actions.cancel-and-back') }}
      </MXButton>
    </div>
  </div>
</template>

<script>
import MXButton from '@/shared/components/MXButton.vue';

export default {
  name: 'FileDropSelector',
  components: {
    MXButton,
  },
  props: {
    acceptedFormats: {
      type: String,
    },
    height: {
      type: String,
      default: 'auto',
    },
    label1: {
      type: String,
    },
    label2: {
      type: String,
    },
    buttonLabel: {
      type: String,
    },
  },
  computed: {
    theLabel1() {
      return this.label1 || this.$t('components.file-drop-selector.label-1');
    },
    theLabel2() {
      return this.label2 || this.$t('components.file-drop-selector.label-2');
    },
    theButtonLabel() {
      return this.buttonLabel || this.$t('components.file-drop-selector.button-label-1');
    },
  },
  methods: {
    onFile(e) {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.srcElement.files[0];
      const normalizedFile = new File([file], file.name.split(' ').join('-'), { type: file.type });
      this.$emit('onFile', normalizedFile);
    },
  },
};
</script>
<style lang="scss" scoped>
.border-2 {
  border: 2px solid;
}
.border-dashed {
  border-style: dashed;
}
#file-select-btn {
  cursor: pointer;

  > div {
    background-color: #7427f1;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
  }
}
</style>
