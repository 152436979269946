<template>
  <div
    :class="`d-flex position-relative  image-uploader image-uploader--${variant}`"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <template v-if="!isUploading">
      <label
        :for="`img-input-${image}`"
        class="position-relative d-flex flex-column justify-content-center rounded-lg overflow-hidden image-uploader__container"
        @click.stop
        @dragover.prevent
        @drop.prevent="onFile"
      >
        <b-img-lazy class="image-uploader__source" v-if="image" fluid :src="image" @click="onFile()" />

        <template v-else>
          <template v-if="$slots.default">
            <slot />
          </template>
          <div class="font-size-md text-center mb-1" v-else>
            <span v-html="$t('components.image-uploader.text')"></span>
          </div>
        </template>

        <hs-icon
          v-if="image && variant === 'simple'"
          icon="pencil"
          class="image-uploader__edit-icon position-absolute d-flex align-items-center justify-content-center rounded-circle"
        />

        <input :id="`img-input-${image}`" class="d-none" type="file" @input="onFile" :multiple="multiple" />
      </label>
      <button
        v-if="image && variant === 'complete'"
        class="position-absolute image-uploader__close border-0 p-0 bg-transparent"
        @click.stop="$emit('onClose')"
      >
        <hs-icon
          icon="trash"
          class="rounded-circle p-2 bg-danger text-white"
          :size="12"
          v-b-tooltip.hover.bottom="removeText"
        />
      </button>
    </template>
    <div
      v-else
      class="image-uploader__uploading position-relative d-flex flex-column justify-content-center rounded-lg overflow-hidden"
    >
      <hs-loading />
    </div>
  </div>
</template>
<script>
import { hsLoading } from '@/components';
import ToastHelper from '@/shared/helpers/toast';
export default {
  name: 'ImageUploader',
  props: {
    image: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
    removeText: {
      type: String,
    },
    width: {
      type: String,
      default: '250',
    },
    height: {
      type: String,
      default: '140',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    acceptedFormats: {
      type: Array,
      default: () => ['image/png', 'image/jpeg'],
    },
    variant: {
      type: String,
      default: 'complete',
    },
  },
  components: {
    hsLoading,
  },
  methods: {
    onFile(event) {
      const files = [...event.srcElement.files];

      if (files.length) {
        if (!files.every(file => this.acceptedFormats.some(format => file.type.match(format)))) {
          ToastHelper.dangerMessage(this.$t('components.image-uploader.upload-error-formats'));
          return;
        }

        this.$emit('onFile', files[0]);
        this.$emit('onMultipleFile', files);
        event.target.value = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-uploader {
  &__container {
    width: inherit;
    height: inherit;
    cursor: pointer;
  }

  &__uploading {
    width: inherit;
    height: inherit;
  }

  &--simple {
    .image-uploader__container {
      margin: 0;
      transition: all 100ms linear;

      .image-uploader__edit-icon {
        top: 50%;
        left: 50%;
        width: 1.6rem;
        height: 1.6rem;
        opacity: 0;
        background-color: #fff;
        transition: inherit;
        transform: translate(-50%, -50%);
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        transition: inherit;
        opacity: 0;
      }

      &:hover {
        .image-uploader__edit-icon {
          opacity: 1;
        }

        &:before {
          background-color: #000;
          opacity: 0.5;
        }
      }
    }
  }

  &--complete {
    .image-uploader__container {
      border: 1px dashed $purple-dark;
    }
    .image-uploader__close {
      outline: none;
      right: -10px;
      top: -10px;
    }
  }
}
</style>
