<template>
  <div class="w-100 d-flex flex-md-row" :class="{ 'flex-column': !inline }">
    <div class="mr-md-1 flex-1" :class="{ 'mr-1': inline }">
      <slot name="date-label"></slot>
      <hs-date
        id="date"
        v-model="date"
        :date-format-options="{ day: 'numeric', year: 'numeric', month: 'numeric' }"
        v-bind="$attrs"
      />
    </div>
    <div class="ml-md-1 flex-1" :class="{ 'ml-1': inline }">
      <slot name="time-label"></slot>
      <hs-time id="time" v-model="time" v-bind="$attrs" />
    </div>
  </div>
</template>

<script>
import { parse, format } from '@/shared/helpers/dates';

export default {
  inheritAttrs: false,
  name: 'hs-date-time',
  props: {
    value: {
      type: Date,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    date: {
      get() {
        return format(this.value, 'YYYY-MM-DD');
      },
      set(newDate) {
        const completeDateString = `${newDate}T${this.time}`;
        this.$emit('input', parse(completeDateString, `YYYY-MM-DD'T'HH:mm:ss`));
      },
    },
    time: {
      get() {
        return format(this.value, 'HH:mm:ss');
      },
      set(newTime) {
        const completeDateString = `${this.date}T${newTime}`;
        this.$emit('input', parse(completeDateString, `YYYY-MM-DD'T'HH:mm:ss`));
      },
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ #date__value_ {
  word-break: unset !important;
}
</style>
