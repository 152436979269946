<template>
  <div class="hs-tab tlw-p-0 md:tlw-p-5">
    <b-tabs
      v-model="tabIndex"
      pills
      :align="!isMobile ? 'center' : ''"
      card
      :vertical="!isMobile"
      :lazy="lazy"
      @activate-tab="controlTabs"
      :nav-wrapper-class="['tabs-list', activeAll && 'tabs-list--active-all']"
      active-nav-item-class="tabs-list--active"
    >
      <b-tab @click="selectedTab(tab)" :title="tab.title" v-for="(tab, index) in items" :key="index">
        <template #title class="d-flex">
          <div class="d-flex tlw-justify-center md:tlw-justify-start">
            <div class="d-flex tab-icon">
              <hs-icon class="pr-2" :icon="tab.icon" />
            </div>
            <span> {{ tab.title }}</span>
          </div>
        </template>
        <section class="p-0" v-if="isLoading">
          <hs-loading />
        </section>
        <section v-show="!isLoading">
          <component
            @editing="onEdit"
            @save="onSave"
            :class="colors"
            :props="tab.props"
            v-bind:is="tab.component"
          ></component>
        </section>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { hsLoading } from '@/components';
import BrowserHelper from '@/shared/helpers/browser';
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    lazy: {
      type: Boolean,
      required: true,
      default: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
    tabActive: {
      type: Number,
      required: true,
      default: 0,
    },
    colors: {
      type: String,
    },
    activeAll: {
      type: Boolean,
      default: false,
    },
  },
  components: { hsLoading },
  data: () => ({
    tabIndex: 0,
    windowWidth: 0,
  }),
  watch: {
    tabActive() {
      this.tabIndex = this.tabActive;
    },
  },
  computed: {
    ...mapState('offerWizard', ['isEditingOffer']),
    isMobile() {
      return this.windowWidth <= 768;
    },
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });
  },
  methods: {
    ...mapMutations('offerWizard', ['setIsEditingOffer']),

    selectedTab(tab) {
      this.$emit('onSelectTab', tab);
    },
    onSave(data) {
      this.$emit('onSave', this.tabIndex, data);
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth;
    },
    controlTabs(newTabIndex, prevTabIndex, bvEvent) {
      if (this.items[newTabIndex].disabled) {
        this.$emit('onSelectDisabledTab', this.items[newTabIndex]);
        bvEvent.preventDefault();
      }

      if (this.isEditingOffer) {
        bvEvent.preventDefault();
        const h = this.$createElement;

        const titleVNode = h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } });

        const messageVNode = h('div', { class: ['exit-offer-modal__content'] }, [
          h('hs-icon', { props: { icon: 'info-circle', size: 47 } }),
          h('h3', {}, 'Deseja sair?'),
          h('p', {}, 'As informações modificadas na sua oferta serão perdidas caso você não clique em salvar.'),
        ]);

        this.$bvModal
          .msgBoxConfirm([messageVNode], {
            centered: true,
            bodyClass: 'hs-modal-body',
            footerClass: 'hs-modal-footer',
            cancelTitle: 'Continuar edição',
            okTitle: 'Sair',
          })
          .then(value => {
            if (value) {
              this.setIsEditingOffer(false);
              this.tabIndex = newTabIndex;
            }
          });
      }
    },
    onEdit() {
      this.$emit('editing');
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
};
</script>
<style lang="scss" scoped>
.hs-tab {
  /deep/ .tabs-list {
    .tab-icon {
      width: 35px;
      justify-content: center;
      align-items: center;
    }
    &--active {
      color: #35126e !important;
      font-weight: 600;
      background-color: $purple !important;
      border: 1px solid #7427f1 !important;
    }
    ul {
      margin: 0;
    }
    li {
      min-width: 225px;
      background-color: white;

      &:hover {
        background-color: #ead3fe;
        a {
          border: 1px solid #ead3fe;
        }
      }
      a {
        color: #262626;
        border: 1px solid #fff;
      }
    }
  }
  .card-body {
    padding: 0 1px;
  }
}
@media only screen and (max-width: $screen-sm) {
  .hs-tab {
    /deep/ .tabs-list {
      margin-top: 2px;
      &--active {
        background-color: initial;
        border: 0;
        border-bottom: 4px solid #ff305c;
        text-decoration: none;
        background-color: transparent;
      }
      ul {
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
        background-color: white;
        display: flex;
        overflow-x: auto;
        width: 100%;
        flex-direction: row;
        flex-wrap: inherit;
      }
      li {
        min-width: 210px;
      }
    }
    .card-body {
      padding: 1px 0;
    }
  }
}

/deep/.hs-modal-body {
  padding-bottom: 0;
}

/deep/.modal-dialog {
  max-width: 341px;
  margin: 0 auto !important;
}

/deep/.hs-modal-footer {
  border: 0;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 8px;

  button {
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 700;
    font-family: 'Muli';
    box-shadow: none;

    &:active {
      background-color: #fff !important;
      box-shadow: none !important;
    }
  }

  button:last-child {
    color: #7427f1;
    text-decoration: underline;
    background-color: transparent;
  }

  button:first-child {
    background-color: #7427f1;
    color: #fff;
  }
}
.exit-offer-modal__content {
  display: flex;
  flex-direction: column;

  i {
    text-align: center;
    color: #db3939;
    margin-bottom: 20px;
  }

  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #000;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 32px;
    text-align: center;
    color: #262626;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 21px;
  }
}
</style>
