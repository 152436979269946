<template>
  <b-container class="container-no-data tlw-absolute tlw-w-full tlw-h-full tlw-max-w-full">
    <div class="tlw-h-full tlw-flex tlw-justify-center tlw-flex-col">
      <div class="tlw-flex tlw-justify-center tlw-mb-6">
        <b-img-lazy fluid class="image" :src="image"></b-img-lazy>
      </div>
      <div class="tlw-flex tlw-justify-center tlw-flex-col">
        <b-row align-h="center">
          <b-col col sm="10" md="8" lg="5" class="tlw-pt-2 tlw-pb-6 tlw-table tlw-m-auto">
            <h3
              data-testid="title"
              class="tlw-text-center tlw-font-bold tlw-mb-4"
              :class="{ [`text-${variant}`]: true }"
            >
              {{ title }}
            </h3>
            <p data-testid="description" class="tlw-text-center" v-html="description" />
          </b-col>
        </b-row>
      </div>
      <div class="tlw-flex tlw-justify-center">
        <slot></slot>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: require('@/assets/images/commons/no_data.svg'),
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      type: String,
    },
    variant: {
      type: String,
      default: 'cherry',
    },
  },
};
</script>

<style lang="scss" scoped>
.container-no-data {
  background: linear-gradient(0deg, $grey-10 0%, rgba(244, 244, 244, 0.7) 100%);
}

h3 {
  font-size: 21px;
  letter-spacing: -0.35px;
  line-height: 23px;
}

p {
  color: $grey;
  font-size: 16px;
  letter-spacing: -0.27px;
  line-height: 19px;
}

.image {
  height: 170px;
}
</style>
