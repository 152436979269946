<template>
  <hs-button class="btn-outline-primary-dashed" v-bind="$attrs" v-on="$listeners">
    <slot />
  </hs-button>
</template>

<script>
export default {
  name: 'hsDashedButton',
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.btn-outline-primary-dashed {
  position: relative;
  display: table;
  background: $white;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px dashed $cyan;
  border-right: 0;

  &::before {
    position: absolute;
    top: 0;
    right: -20px;
    height: 0px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid white;
    content: '';
  }

  &::after {
    position: absolute;
    top: 6px;
    right: -15px;
    width: 27px;
    height: 26px;
    border-top: 1px dashed $cyan;
    border-right: 1px dashed $cyan;
    transform: rotate(45deg);
    content: '';
  }

  &:hover {
    background: $white;
  }
}

/deep/ .fal {
  color: $cyan !important;
}
</style>
