<template>
  <hs-modal :id="id" :scrollable="false" size="xl" :hideHeader="true" bodyClass="p-0" hide-footer>
    <div class="row">
      <div class="col-7 d-flex flex-column align-items-center p-4">
        <h3 class="font-weight-bold mt-4">
          {{ $t('components.premium-modal.title') }}
        </h3>
        <p class="font-size-md my-3 text-center">
          {{ $t('components.premium-modal.sub-title') }}
        </p>
        <div class="d-flex flex-wrap mb-3">
          <div class="w-50 mt-1" v-for="n in 6" :key="`feature_${n}`">
            <div class="d-flex justify-content-start mt-3 ml-4">
              <hs-icon
                variant="light"
                :icon="featuresIcons[n - 1]"
                class="p-2 align-self-start rounded-circle bg-info-light text-primary"
                :size="11"
              />
              <p class="text-left font-size-xs font-weight-bold ml-1 mb-2">
                {{ $t(`components.premium-modal.features.${n}`) }}
              </p>
            </div>
          </div>
        </div>
        <hs-button class="mt-5 mb-3" variant="primary" @click="$emit('click')">{{
          $t('components.premium-modal.button')
        }}</hs-button>
      </div>
      <b-img-lazy
        class="col-5 bg-info-light"
        fluid
        :src="require('@/assets/images/SparkMembers/commons/hero_premium.svg')"
      />
    </div>
  </hs-modal>
</template>

<script>
import { hsModal } from '@/components';

export default {
  name: 'hsPremiumModal',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      featuresIcons: ['envelope', 'chart-line-down', 'thumbs-up', 'paper-plane', 'wallet', 'star'],
    };
  },
  components: {
    hsModal,
  },
};
</script>
