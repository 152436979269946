<template>
  <div class="tlw-flex">
    <div class="tlw-w-full">
      <slot name="content"></slot>
    </div>
    <transition name="slide-fade">
      <slot v-if="opened" name="sidebar"></slot>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'hsSidebar',
  props: {
    opened: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
