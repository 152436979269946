<template>
  <div class="position-relative d-flex align-items-center flex-column h-100">
    <div class="w-100">
      <slot />
    </div>
    <NoData :title="title" :description="description" :image="image">
      <div class="d-block d-sm-block d-md-none">
        <hs-button @click="act" variant="primary" type="button" size="sm">
          {{ buttonTitle }}
        </hs-button>
      </div>
      <div class="d-none d-md-block d-lg-block">
        <hs-button @click="act" variant="primary" type="button">
          {{ buttonTitle }}
        </hs-button>
      </div>
    </NoData>
  </div>
</template>

<script>
import NoData from './NoData';
export default {
  name: 'no-table-data',
  props: {
    image: {
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      type: String,
    },
    buttonTitle: {
      required: true,
      type: String,
    },
  },
  components: {
    NoData,
  },
  methods: {
    act() {
      this.$emit('action-clicked');
    },
  },
};
</script>
