












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class HsButton extends Vue {
  @Prop({ default: 'primary' }) color: string;

  get colors() {
    return {
      blue: 'tlw-bg-blue-500 tlw-text-white hover:tlw-bg-blue-600',
      purple: 'tlw-bg-purple-500 tlw-text-white hover:tlw-bg-purple-600',
    }[this.color];
  }
}
